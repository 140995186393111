import Vue from 'vue'
import VueRouter from 'vue-router'
import versionTood from '@/utils/version'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    meta: { ifDoFresh: false, keepAlive: true },
    component: () => import('../views/index.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    meta: { ifDoFresh: false, keepAlive: false },
    component: () => import('../views/jobDetail/index.vue')
  },
  {
    path: '/jobList',
    name: 'jobList',
    meta: { ifDoFresh: false, keepAlive: true },
    component: () => import('../views/jobList/index.vue')
  },
  {
    path: '/rollJobList',
    name: 'rollJobList',
    meta: { ifDoFresh: false, keepAlive: true },
    component: () => import('../views/rollJobList/index.vue')
  },
  {
    path: '/postService',
    name: 'postService',
    meta: { ifDoFresh: false, keepAlive: false },
    component: () => import('../views/postService/index.vue')
  },
  {
    path: '/employment',
    name: 'employment',
    meta: { ifDoFresh: false, keepAlive: false },
    component: () => import('../views/postService/employment/index.vue')
  },
  {
    path: '/jobfair',
    name: 'jobfair',
    meta: { ifDoFresh: false, keepAlive: false },
    component: () => import('../views/postService/job-fair/index.vue')
  },
  {
    path: '/jobfairDetail',
    name: 'jobfairDetail',
    meta: { ifDoFresh: false, keepAlive: true },
    component: () => import('../views/postService/job-fair/detail/index.vue')
  },
  {
    path: '/QRphoto',
    name: 'QRphoto',
    meta: { ifDoFresh: false, keepAlive: false },
    component: () => import('../views/postService/QRphoto/index.vue')
  },
  {
    path: '/article_common_page',
    name: 'article_common_page',
    meta: { ifDoFresh: false, keepAlive: false },
    component: () => import('../views/article_common_page/index.vue')
  },
{
    path: '/test',
        name: 'test',
    meta: { ifDoFresh: false, keepAlive: false },
    component: () => import('../views/article_common_page/test/index.vue')
},
  {
    path: '/articleDetail',
    name: 'articleDetail',
    meta: { ifDoFresh: false, keepAlive: false },
    component: () => import('../views/article_common_page/detail/index.vue')
  },
  {
    path: '/module_common_page',
    name: 'module_common_page',
    meta: { ifDoFresh: false, keepAlive: false },
    component: () => import('../views/module_common_page/index.vue')
  },
  {
    path: '/face-recognition',
    name: 'face-recognition',
    meta: { ifDoFresh: false, keepAlive: false },
    component: () => import('../views/face-recognition/index.vue')
  }, 
  {
    path: '/payrollList',
    name: 'payrollList',
    meta: { ifDoFresh: false, keepAlive: false },
    component: () => import('../views/payrollList/index.vue')
  },
  {
    path: '/Regional_jobList',
    name: 'Regional_jobList',
    meta: { ifDoFresh: false, keepAlive: false },
    component:() => import('../views/Regional_jobList/index.vue')
  },
  {
    path: '/external_page',
    name: 'external_page',
    meta: { ifDoFresh: false, keepAlive: false },
    component: () => import('../views/external_page/index.vue')
  },
  {
    path: '/Applylist',
    name: 'Applylist',
    meta: { ifDoFresh: false, keepAlive: false },
    component:() => import('../views/Apply/List/index.vue')
  },
  {
    path: '/Applydetail',
    name: 'Applydetail',
    meta: { ifDoFresh: false, keepAlive: false },
    component:() => import('../views/Apply/detail/index.vue')
  }, 
  {
    path: '/SubIndex',
    name: 'SubIndex',
    meta: { ifDoFresh: false, keepAlive: true },
    component:() => import('../views/SubIndex/index.vue')
  },
  {
    path: '/liveStreaming',
    name: 'liveStreaming',
    meta: { ifDoFresh: false, keepAlive: true },
    component:() => import('../views/liveStreaming/index.vue')
  },
  {
    path: '/liveStreamList',
    name: 'liveStreamList',
    meta: { ifDoFresh: false, keepAlive: false },
    component:() => import('../views/liveStreaming/list/index.vue')
  },
  {
    path: '/AgoraRTC',
    name: 'AgoraRTC',
    meta: { ifDoFresh: false, keepAlive: true },
    component:() => import('../views/AgoraRTC/index.vue')
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (from.path === '/detail') {
      return {};
    } else {
      return { x: 0, y: 0 };
    }
    
  }
})
const originalPush = VueRouter.prototype.push;
 
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
  // console.log('beforeEach', to, from)
  // 打印当前路由的信息 可以是一个对象或者一个数
  //判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
  versionTood.isNewVersion();
  next()
})

export default router
